import settingServices from '@/modules/doctorDashboard/settings/services/settings'
import hospitalSettingServices from '@/modules/hospitalDashboard/settings/services/settings'
import authMixins from './auth-permission'
export default {
  mixins: [authMixins],
  data () {
    return {
      clientInfo: ''
    }
  },
  methods: {
    userRole (type) {
      if (type === 'doctor' && this.hasPer('veterinary.doctor')) {
        settingServices.getDoctorInfo().then(res => {
          this.clientInfo = res.data
          if (res.data.status !== 'accepted') {
            switch (res.data.status) {
              case 'pending' : {
                this.$router.push({ name: 'doctorSettings' })
                break
              }
              case 'processing' : {
                // this.$router.push({ name: 'doctorProcessing' })
                this.$router.push({ name: 'doctorSettings' })
                break
              }
              default :
                this.$router.push({ name: 'doctorDashboard' })
                break
            }
          }
        })
      } else if (type === 'clinic_owner' && this.hasPer('veterinary.clinic_owner')) {
        hospitalSettingServices.getClinicInfo().then(res => {
          this.clientInfo = res.data
          if (res.data.status !== 'accepted') {
            switch (res.data.status) {
              case 'pending' : {
                this.$router.push({ name: 'hospitalSetting' })
                break
              }
              case 'processing' : {
                this.$router.push({ name: 'hospitalSetting' })
                break
              }
              default :
                // localStorage.setItem('clientStatus', 'accepted')
                this.$router.push({ name: 'hospitalDashboard' })
                break
            }
          }
        })
      }
    },
    getInfo (type = 'doctor') {
      this.userRole(type)
    }
  },
  created () {
  }
}
